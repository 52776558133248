import React from 'react';
import './WhoWeAre.css';

const WhoWeAre = () => {
  return (
    <div className="who-we-are-container">
      <section className="leader-section">
        <div className="leader-content">
        <h1 className="title">Meet Our Leaders</h1>
        <div className="leader">
            <div className="leader-info">
              <h2>Junaid Nari</h2>
              <p>President</p>
              <p>
              Junaid Nari is an entrepreneur with 30+ years of experience in playing pivotal roles directly influencing the future of a company that covers new businesses, by partnering strategy with a passion for growing the business. Currently, he is the Founder and CEO of BrainVault Technologies with branches in India and the UK, and headquarters in the US. Junaid is also the Co-Founder, Technology Partner, and Promoter of the American startup, SportsGravy, that is looking to revolutionize how athletes are developed worldwide.
Junaid leads the charge and owns the responsibility for aspects of driving revenue to Fintrend Technologies including, corporate development, equity investment, and joint venture activity on a global scale.
In the past, as a Co-Founder and Leader of a Fintech firm, he raised significant capital from a Private Equity firm and successfully exited a cross-border payments technology platform business by selling to a leading multinational company.
               </p>
            </div>
            <div className="leader-image">
              <img src="/images/junaid.jpg" alt="Junaid Nari" className="ceo-image" />
            </div>
          </div>

          <div className="leader">
            <div className="leader-info">
              <h2>Sai Prakash Govindarajan</h2>
              <p>CEO & Executive Vice President (Global Markets)</p>
              <p>
              Sai Prakash (Sai) has held several regional and global leadership roles in multinational companies. Sai is a versatile professional and change leader with several cross-enterprise critical skills across Banking, Energy Industries, and other Industrial domains. Sai has significant experience in Business Strategy and Planning & Operations, and played a key role as Head of Business Development for the Regional Management Leadership Team in a global organization, that grew business revenues from 400 MUSD to 900 MUSD in the Asia Pacific region. Sai was also instrumental in several mergers and acquisitions deals, where he unlocked synergistical relationships, led strategic direction overhaul, and oversaw successful integration. Sai holds wide experience in leading large cross-border and cross-functional teams in Operations and Business Development.
Sai is responsible for the overall direction and growth of Fintrend Technologies with direct oversight of Revenues and Operations.
Sai is an Electrical and Electronics Engineer, and is a Certified Management Accountant (IMA). He is also a Certified Six Sigma Black Belt and a Certified Financial Services Auditor from IIA (Institute of Internal Auditors), and is based in Canada.
                </p>
            </div>
            <div className="leader-image">
              <img src="/images/SaiG.jpg" alt="Sai Prakash Govindarajan" className="ceo-image" />
            </div>
          </div>

         

        </div>
      </section>
    </div>
  );
};

export default WhoWeAre;
