// src/components/ComingSoon.jsx
import React from "react";
import "./comingSoon.css"; // You can style this page as needed

const ComingSoon = () => {
  return (
    <div className="coming-soon">
     
      <p>please contact support@finflow.com for demo account setup</p>
    </div>
  );
};

export default ComingSoon;
