import React from "react";

const Back = ({ title }) => {
  return (
    <>
      <section className='back'>
        <h2>Home /</h2>
        <h1>{title}</h1>
      </section>
      <div className='margin'></div>
    </>
  );
};

export default Back;
